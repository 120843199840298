
<template>
  <div>
    <!-- 零售走势 -->
    <filter-view :loading="loading" @submit="loadEchart">
      <retail-Filter :show-opt="showOpt" :query="query" @init="loadEchart" />
    </filter-view>
    <div class="result">
      <template v-if="sumData">
        <div class="padding-10 fs-small fc-g">数据概览</div>
        <div class="round-panel bc-w padding-10">
          <div class="h s wrap">
            <template v-for="f in sumFields">
              <div :key="f.name" class="v c padding-10" style="width: 50%;" v-if="f.show !== isReported">
                <div>{{f.name}}</div>
                <div class="bold fc-p fs-large gap-1x">{{f.price ? $price(sumData[f.field]) : sumData[f.field]}}</div>
                <div v-if="sumData.compareYearData">
                  <div class="h c">
                    <div class="fc-g ta-r" style="width: 80px;">{{sumData.compareYear}}年：</div>
                    <div>{{f.price ? $price(sumData.compareYearData[f.field]) : sumData.compareYearData[f.field]}}</div>
                  </div>
                  <div class="h c">
                    <div class="fc-g ta-r" style="width: 80px;">同比增长率：</div>
                    <div style="color: #cc4125">{{((sumData.compareYearData[f.ratioField] || 0) * 100).toFixed(2)}}%</div>
                  </div>
                  <div v-if="sumData.compMonthData" class="h c">
                    <div class="fc-g ta-r" style="width: 80px;">环比增长率：</div>
                    <div style="color: #6fa8dc">{{((sumData.compMonthData[f.ratioField] || 0) * 100).toFixed(2)}}%</div>
                  </div>
                </div>
              </div>
            </template>
          </div>
          <div class="h sb">
            <template v-if="isReported">
              <div class="v c padding-10">
                <div>已上报待发货数量</div>
                <div class="bold fc-p fs-large gap-1x">{{sumData.unCheckSendNumber || 0}}</div>
              </div>
              <div class="v c padding-10">
                <div>已上报待发货金额</div>
                <div class="bold fc-p fs-large gap-1x">{{$price(sumData.unCheckSendMoney || 0)}}</div>
              </div>
              <div class="v c padding-10">
                <div>已上报待发货成本</div>
                <div class="bold fc-p fs-large gap-1x">{{$price(sumData.unCheckSendCost || 0)}}</div>
              </div>
            </template>
            <template v-else>
              <div class="v c padding-10">
                <div>待发货数量</div>
                <div class="bold fc-p fs-large gap-1x">{{sumData.unSendNumber || 0}}</div>
              </div>
              <div class="v c padding-10">
                <div>待发货金额</div>
                <div class="bold fc-p fs-large gap-1x">{{$price(sumData.unSendMoney || 0)}}</div>
              </div>
              <div class="v c padding-10">
                <div>待发货成本</div>
                <div class="bold fc-p fs-large gap-1x">{{$price(sumData.unSendCost || 0)}}</div>
              </div>
            </template>
          </div>
        </div>
      </template>
      <div class="padding-10 fs-small fc-g">分析结果</div>
      <div class="round-panel bc-w">
        <div v-if="store && store.length">
          <div class="c h sb">
            <div class="no-flex padding-10">显示同比</div>
            <el-switch v-model="options.yearOnYearShow" @change="draw" />
          </div>
          <div class="c h sb sep-t">
            <div class="no-flex padding-10">显示数据</div>
            <el-checkbox-group v-model="options.fields" @change="draw">
              <el-checkbox v-for="f in fieldDefines" :label="f.field" :key="f.field">{{ f.name }}</el-checkbox>
            </el-checkbox-group>
          </div>
          <div class="sep-t" ref="chart" style="height:340px;"></div>
        </div>
        <div class="fc-g ta-c padding-10" v-else>没有分析结果，请更改统计分析条件或稍候重试。</div>
      </div>
      <template v-if="store && store.length > 0">
        <div class="padding-10 fs-small fc-g">数据列表</div>
        <div class="round-panel bc-w">
          <div class="padding-05 sep-b" v-for="o in pagedStore" :key="o.id">
            <div class="fs-large bold padding-05" v-if="query.dateGroup === 'year'">{{ o.year }}年</div>
            <div class="fs-large bold padding-05" v-else-if="query.dateGroup === 'month'">{{ o.year }}年{{ o.month }}月</div>
            <div class="fs-large bold padding-05" v-else-if="query.dateGroup === 'day'">{{ o.year }}年{{ o.month }}月{{ o.day }}日</div>
            <template v-if="isReported">
              <div class="h c wrap fs-small lh-150">
                <div class="padding-05" style="width: 50%;">
                  销售金额：{{ $price(o.realCheckSumMoney) }}
                  <br />
                  同比金额：{{ $price(o.compareData && o.compareData.realCheckSumMoney) }}
                  <br />
                  同比增长率：{{ $percent(o.realCheckSumMoneyRatio) }}
                </div>
                <div class="padding-05" style="width: 50%;">
                  销售数量：{{ o.checkSaleNumber || "-" }}
                  <br />
                  同比数量：{{ o.compareData ? o.compareData.checkSaleNumber : "-" }}
                  <br />
                  同比增长率：{{ $percent(o.checkSaleNumberRatio) }}
                </div>
                <div class="padding-05" style="width: 50%;">
                  发货金额：{{ $price(o.checkSendMoney) }}
                  <br />
                  同比金额：{{ $price(o.compareData && o.compareData.checkSendMoney) }}
                  <br />
                  同比增长：{{ $percent(o.checkSendMoneyRatio) }}
                </div>
                <div class="padding-05" style="width: 50%;">
                  发货数量：{{ o.checkSendNumber || "-" }}
                  <br />
                  同比数量：{{ o.compareData ? o.compareData.checkSendNumber : "-" }}
                  <br />
                  同比增长率: {{$percent(o.checkSendNumberRatio)}}
                </div>
              </div>
            </template>
            <template v-else>
              <div class="h c wrap fs-small lh-150">
                <div class="padding-05" style="width: 50%;">
                  销售金额：{{ $price(o.realSumMoney) }}
                  <br />
                  同比金额：{{ $price(o.compareData && o.compareData.realSumMoney) }}
                  <br />
                  同比增长率：{{ $percent(o.realSumMoneyRatio) }}
                </div>
                <div class="padding-05" style="width: 50%;">
                  销售数量：{{ o.saleNumber || "-" }}
                  <br />
                  同比数量：{{ o.compareData ? o.compareData.saleNumber : "-" }}
                  <br />
                  同比增长率：{{ $percent(o.saleNumberRatio) }}
                </div>
                <div class="padding-05" style="width: 50%;">
                  发货金额：{{ $price(o.sendMoney) }}
                  <br />
                  同比金额：{{ $price(o.compareData && o.compareData.sendMoney) }}
                  <br />
                  同比增长：{{ $percent(o.sendMoneyRatio) }}
                </div>
                <div class="padding-05" style="width: 50%;">
                  发货数量：{{ o.sendNumber || "-" }}
                  <br />
                  同比数量：{{ o.compareData ? o.compareData.sendNumber : "-" }}
                  <br />
                  同比增长率: {{$percent(o.sendNumberRatio)}}
                </div>
              </div>
            </template>
          </div>
          <div class="ta-c">
            <el-pagination class="h5-pagination" :total="totalElements" :current-page.sync="page" :page-size="size" layout="prev, pager, next" hide-on-single-page />
          </div>
        </div>
      </template>
    </div>
  </div>
</template>
      
<script>
import filterView from "../assembly/filter";
import retailFilter from "../assembly/filter/retail";
import request from "@/utils/request";
export default {
  components: {
    filterView,
    retailFilter,
  },
  data() {
    let now = new Date();
    return {
      loading: false,
      page: 1,
      size: 6,
      totalElements: null,
      store: [],
      isReported: false,
      chart: null,
      showOpt: {
        showDimension: true, //分析维度
        showRangGoods: true, //商品范围
        showCommodity: true, //指定商品
        showCompareyear: true, //同比年度
        showReported: true, //只统计已上报
        showLevel: false, //汇总级别
        showRankGist: false, //显示排名依据
        showRank: false, //显示排名对象
        showYearonyear: false, //同比显示
        showMonth: false, //环比显示
        showCategory: false, //对比类型
        showTimeRange: true, //时间范围
        showDetail: false, //显示零售明细查询
      },
      query: {
        addressType: "area",
        area: {
          treeNodeId: "1",
          treeNodeType: "group",
        },
        standard: {
          treeNodeId: "",
          StandardAreaGroup: "country", //标准行政区域
        },
        dateGroup: "day",
        begDate: new Date(
          now.getFullYear(),
          now.getMonth() - 3,
          now.getDate()
        ).getTime(),
        endDate: new Date(
          now.getFullYear(),
          now.getMonth(),
          now.getDate(),
          23,
          59,
          59
        ).getTime(),
        brandId: null, //商品范围 品牌
        seriesId: null, //商品范围 系列
        categoryId: null, //商品范围 分类
        goods: null, //指定商品
        compareYear: now.getFullYear() - 1, //同比年度
        reportOnly: false, //只统计已上报
        page: 0,
        size: 10000,
      },

      sumData: null,
      sumFields: [
        {
          name: "销售金额",
          price: true,
          field: "realSumMoney",
          ratioField: "realSumMoneyRatio",
          show: true,
        },
        {
          name: "销售数量",
          field: "saleNumber",
          ratioField: "saleNumberRatio",
          show: true,
        },
        {
          name: "出货数量",
          field: "sendNumber",
          ratioField: "sendNumberRatio",
          show: true,
        },
        {
          name: "出货金额",
          price: true,
          field: "sendMoney",
          ratioField: "sendMoneyRatio",
          show: true,
        },
        {
          name: "已上报销售金额",
          price: true,
          field: "realCheckSumMoney",
          ratioField: "realCheckSumMoneyRatio",
          show: false,
        },
        {
          name: "已上报销售数量",
          field: "checkSaleNumber",
          ratioField: "checkSaleNumberRatio",
          show: false,
        },
        {
          name: "已上报出货数量",
          field: "checkSendNumber",
          ratioField: "checkSendNumberRatio",
          show: false,
        },
        {
          name: "已上报出货金额",
          price: true,
          field: "checkSendMoney",
          ratioField: "checkSendMoneyRatio",
          show: false,
        },
      ],

      options: {
        yearOnYearShow: false,
        fields: ["realSumMoney_checkRealSumMoney"],
        YOYFields: ["realSumMoney_checkRealSumMoney"],
      },
      fieldDefines: [
        {
          field: "saleNumber_checkSaleNumber",
          name: "销售数量",
        },
        {
          field: "realSumMoney_checkRealSumMoney",
          name: "销售金额",
        },
        {
          field: "sendNumber_checkSendNumber",
          name: "出货数量",
        },
        {
          field: "sendMoney_checkSendMoney",
          name: "出货金额",
        },
      ],
      fields: {
        saleNumber_checkSaleNumber: {
          label: "销售数量",
          key: "saleNums",
          ratio: 1,
          yIndex: 1,
          sort: 1,
        },
        realSumMoney_checkRealSumMoney: {
          label: "销售金额",
          key: "saleMoney",
          ratio: 0.000001,
          yIndex: 0,
          sort: 2,
        },
        sendNumber_checkSendNumber: {
          label: "出货数量",
          key: "sendNums",
          ratio: 1,
          yIndex: 1,
          sort: 3,
        },
        sendMoney_checkSendMoney: {
          label: "出货金额",
          key: "sendMoney",
          ratio: 0.000001,
          yIndex: 0,
          sort: 4,
        },
      },
    };
  },
  watch: {
    store() {
      this.page = 1;
    },
  },
  computed: {
    pagedStore() {
      let start = (this.page - 1) * this.size,
        len = this.size;
      if (start + len > this.store.length) {
        len = this.store.length - start;
      }
      return this.store.slice(start, start + len);
    },
  },
  methods: {
    makeParams() {
      let params = JSON.parse(JSON.stringify(this.query));
      if (!params.reportOnly) delete params.reportOnly;
      params.begDate = new Date(params.begDate);
      params.endDate = new Date(params.endDate);
      if (params.addressType == "standard") {
        Object.assign(params, params.standard);
      } else {
        Object.assign(params, params.area);
      }
      delete params.standard;
      delete params.area;
      if (params.dateGroup === "year") {
        params.begDate = new Date(params.begDate.getFullYear(), 0, 1).getTime();
        params.endDate = new Date(
          params.endDate.getFullYear() + 1,
          0,
          1
        ).getTime();
        delete params.compareYear;
      } else if (params.dateGroup === "month") {
        params.begDate = new Date(
          params.begDate.getFullYear(),
          params.begDate.getMonth(),
          1
        ).getTime();
        params.endDate = new Date(
          params.endDate.getFullYear(),
          params.endDate.getMonth() + 1,
          1
        ).getTime();
      } else {
        params.begDate = new Date(
          params.begDate.getFullYear(),
          params.begDate.getMonth(),
          params.begDate.getDate()
        ).getTime();
        params.endDate = new Date(
          params.endDate.getFullYear(),
          params.endDate.getMonth(),
          params.endDate.getDate() + 1
        ).getTime();
      }
      if (params.goods && params.goods.length)
        params.skuIds = params.goods.map((o) => o.id);
      delete params.goods;
      return params;
    },
    loadEchart() {
      if (this.query.reportOnly == false) {
        this.isReported = false;
      } else {
        this.isReported = true;
      }
      let params = Object.assign({}, this.makeParams());
      this.loading = true;

      request({
        url: "@host:analysis;api/analy/retail/shop/sale/sum",
        method: "get",
        params,
      })
        .then((res) => {
          this.sumData = res;
        })
        .catch((err) => {
          this.sumData = {};
        })
        .finally((_) => {
          this.loading = false;
        });

      request({
        url: "@host:analysis;api/analy/retail/sale/trend",
        method: "get",
        params,
      })
        .then((res) => {
          this.store = res.content;
          this.totalElements = res.totalElements;
          this.$nextTick((_) => {
            this.draw();
          });
        })
        .catch((err) => {
          this.store = [];
        })
        .finally(() => {
          this.loading = false;
        });
    },
    draw() {
      if (this.store && this.store.length) {
        let series = {};
        let legData = [];
        let times = [];
        let seriesData = [];
        this.store.forEach((d) => {
          if (d.year != 0 && d.month != 0 && d.day != 0) {
            let dt = d.year + "/" + d.month + "/" + d.day;
            times.push(dt);
          } else if (d.year != 0 && d.month != 0) {
            let dt = d.year + "/" + d.month;
            times.push(dt);
          } else {
            let dt = d.year;
            times.push(dt);
          }
        });
        this.options.fields.forEach((fn) => {
          let fns = fn.split("_");
          let fd = this.fields[fn];
          this.store.forEach((d) => {
            if (!series.hasOwnProperty(fd.key)) {
              series[fd.key] = {
                name: fd.label,
                type: "line",
                yAxisIndex: fd.yIndex,
                smooth: true,
                data: [],
                sort: fd.sort,
              };
              seriesData.push(series[fd.key]);
            }
            series[fd.key].data.push(
              Math.round(
                (d[fns[this.query.reportOnly ? 1 : 0]] || 0) * fd.ratio * 100
              ) / 100
            );
            if (this.options.yearOnYearShow) {
              let yk = "yoy_" + fd.key;
              if (!series.hasOwnProperty(yk)) {
                series[yk] = {
                  name: "同比" + fd.label,
                  type: "line",
                  yAxisIndex: fd.yIndex,
                  smooth: true,
                  data: [],
                };
                seriesData.push(series[yk]);
              }
              series[yk].data.push(
                Math.round(
                  (d.compareData
                    ? d.compareData[fns[this.query.reportOnly ? 1 : 0]] || 0
                    : 0) *
                    fd.ratio *
                    100
                ) / 100
              );
            }
          });
        });

        seriesData.sort((a, b) => a.sort - b.sort);

        if (!this.chart) {
          this.chart = echarts.init(this.$refs.chart, "light");
        }
        this.chart.clear();
        this.chart.setOption({
          tooltip: {
            trigger: "axis",
          },

          legend: {
            show: true,
            left: "center",
            top: 10,
            type: "scroll",
          },
          grid: {
            left: 30,
            right: 30,
            bottom: 20,
            top: 70,
            containLabel: true,
          },
          xAxis: {
            type: "category",
            boundaryGap: false,
            data: times,
          },
          yAxis: [
            {
              type: "value",
              name: "金额（万元）",
              splitNumber: 5,
            },
            {
              type: "value",
              name: "数量",
              splitNumber: 5,
            },
          ],
          series: seriesData,
        });
      } else {
        this.chart = null;
      }
    },
  },
};
</script>